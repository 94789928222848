.footerback{
    background-color: #e9e9e9;
}
.footer-text{
    font-size: 13px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 600;
}

.footer-link{
    text-decoration: none;
    color: #000000;
    font-size: 15px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
}
.footer-link:hover{
    text-decoration: none;
    color: #051b35;
}

.footer-menu-title{
    font-size: 18px;
    color: #000000;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 600;
}